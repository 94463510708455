<template>
    <section id="CreateBook">
        <div class="card card--form">
            <form @submit="submit" v-if="!success">
                <h3>New Book</h3>
                <input type="text" placeholder="My Book Name" v-model="book">
                <p class="footnote">This does not need to be unique. A random Book ID will be simultaneously generated to identify the new book.</p>
                <Button arrow :disabled="!isReady">Create</Button>
            </form>
            <p class="error" v-if="error">{{ error }}</p>
            <div class="success" v-if="success">
                <h3>Success!</h3>
                <p>Your new book <strong>{{ book }}</strong> has been created. Press the button below to manage your books.</p>
                <router-link :to="{ name: 'Manage' }">
                    <Button arrow>Manage</Button>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>

    // Components.
    import Button from '@/components/Button.vue';

    // Firebase.
    import { getProfile } from '@/firebase/auth.js';
    import { createBook } from '@/firebase/books.js';

    export default {
        name: 'CreateBook',
        components: {
            Button,
        },
        data() {
            return {
                book: '',
                error: null,
                success: false,
            }
        },
        computed: {
            isReady() {
                return this.book.trim() !== '';
            },
            header() {

            }
        },
        mounted() {
            this.$store.dispatch('setNavShadow', false);
        },
        methods: {
            submit(e) {

                e.preventDefault();

                this.error = null;
                this.$store.dispatch('updateLoading', 1);

                const book = this.book.trim().replace('/', '-');

                if (book === '') {
                    this.error = 'Please include at least one non-space character.';
                    this.$store.dispatch('updateLoading', -1);
                    return;
                }

                createBook(this.user.uid, book).then(() => {
                    getProfile(this.user.uid).then(snapshot => {
                        this.success = true;
                        this.$store.dispatch('setProfile', snapshot.val());
                        this.$store.dispatch('updateLoading', -1);
                    });
                }).catch(error => {
                    this.error = error;
                    this.$store.dispatch('updateLoading', -1);
                });

            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .card {
        margin: size(Medium) auto;
    }

    input, p {
        margin-top: size(Small);
    }

    .Button {
        margin-top: size(Large);
    }

</style>
